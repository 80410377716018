/* eslint eqeqeq: "off" */
import React, { useState, useCallback } from "react"
import { navigate } from "gatsby"
import Layout from "../ui/Layout"
import connectStore from "../state/connectStore"
import WizardMessage from "../ui/WizardMessage"
import {
  Input,
  Button,
  Message,
} from "semantic-ui-react"
import Color from "../style/Color"

const MAX_NAME_LENGTH = 10;

const Page = ({ location, order, setCustomerName, recentName }) => {
  const [customerName, onSetCustomerName] = useState(order.customerName || recentName || "");

  const onNext = useCallback(() => {
    setCustomerName(customerName)
    navigate("/order-review");
  }, [customerName, setCustomerName]);

  const [showNameMessage, setShowNameMessage] = useState(false);

  const onSubmitName = () => {
    if(customerName.length == 0) {
      setShowNameMessage(true);
    } else {
      onNext();
    }
  };

  return (
    <Layout location={location} to="/order-review" header="Enter your name." showBag
      footer={
        <Button
          content="Next"
          onClick={onSubmitName}
          color={Color.accent}
          icon="angle right"
          labelPosition="right"
          size="huge"
        />
      }
    >
      <Input
        placeholder="Name"
        size="huge"
        value={customerName}
        onChange={(e, { value }) => onSetCustomerName(value.length > MAX_NAME_LENGTH ? value.substr(0, MAX_NAME_LENGTH) : value)}
        onKeyPress={({ key }) => key == "Enter" && onSubmitName()}
        action>
        <input />
        <Button icon="angle right" color={Color.accent} onClick={onSubmitName}/>
      </Input>
      <Message compact>Example: John S</Message>
      <WizardMessage visible={showNameMessage} onTimeout={() => setShowNameMessage(false)}>
        Please enter a name before continuing
      </WizardMessage>
    </Layout>
  );
};

export default connectStore(Page);
